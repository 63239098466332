
import React from 'react';
import { SpotlightWaitlistConstants } from './Constants';
import Image from 'next/image';

export default function Loader() {
    return (
        <div><Image src={SpotlightWaitlistConstants.loader} className="loaderImg" height={10} width={10} /></div>
    );
}
